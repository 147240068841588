<template>
  <!-- First Row -->
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="9"
        lg="8"
        md="7"
      >
        <b-card>

          <b-row>

            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">

                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      Item code : <br><br> <span class="card-text"> {{ product.item_code }}</span>

                    </h4>
                  </div>
                  <div class="d-flex flex-wrap ">
                    <b-button
                      :to="{ name: 'edit-product', params: { id: product.id } }"
                      variant="primary"
                    >
                      Update
                    </b-button>

                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2" />
            </b-col>

            <!-- Right Col: Table -->

          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
        xl="3"
        lg="4"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
            <h5 class="mb-0" />
            <b-badge variant="light-primary">
              QR code
            </b-badge>
          </b-card-header>

          <b-card-body>

            <qrcode-vue
              :value="value"
              :size="size"
              level="H"
            />
          </b-card-body>

        </b-card>

      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <b-card>

          <table class="mt-2 mt-xl-0 w-100">
            <h5 class="text-primary font-weight-bolder mb-2">
              <feather-icon icon="FileTextIcon" />
              Import details
            </h5>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">NBR PCS / CTN</span>
              </th>
              <td class="pb-50">
                {{ product.nbr_import }}                  </td></tr>
            <tr>

              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">NW / CTN</span>
              </th>
              <td class="pb-50">
                {{ product.nw_import }}                  </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">GW / CTN</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ product.gw_import }}                 </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">CBM / CTN</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ product.cbm_import }}                    </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Cost price / PCS</span>
              </th>
              <td class="pb-50">
                {{ product.pa_pcs_import }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Cost price / CTN</span>
              </th>
              <td>
                {{ product.pa_ctn_import }}                  </td>
            </tr>
          </table></b-card>

      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <b-card>

          <table class="mt-2 mt-xl-0 w-100">
            <h5 class="text-primary font-weight-bolder mb-2">
              <feather-icon icon="FileTextIcon" />
              Export details
            </h5>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">NBR PCS / CTN</span>
              </th>
              <td class="pb-50">
                {{ product.nbr_export }}                  </td></tr>
            <tr>

              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">NW / CTN</span>
              </th>
              <td class="pb-50">
                {{ product.nw_export }}                  </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">GW / CTN</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ product.gw_export }}                     </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">CBM / CTN</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ product.cbm_export }}                     </td>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Sales price / PCS </span>
              </th>
              <td class="pb-50">
                {{ product.pv_pcs_export }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="ChevronsRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Sales price / CTN</span>
              </th>
              <td>
                {{ product.pv_ctn_export }}
              </td>
            </tr>
          </table></b-card>

      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="12"
      >
        <b-card>
          <h4 class="mb-2">
            Stock
          </h4>
          <app-timeline>
            <app-timeline-item

              title="N°PCS in way to Tunisia"
              :subtitle="product.stock_in_way_tunisia"
              time="In way to Tunisia"
              variant="warning"
            />
            <app-timeline-item

              title="N°PCS received in Tunisia"
              :subtitle="product.stock_received_tunisia"
              time="Received in Tunisia"
              variant="success"
            />

            <app-timeline-item

              title="N°PCS in way to Brazil"
              :subtitle="product.stock_in_way_brazil"
              time="In way to Brazil"
              variant="danger"
            />
            <app-timeline-item

              title="N°PCS received in Brazil"
              :subtitle="product.stock_received_brazil"
              time="Received in Brazil"
              variant="success"
            />
          </app-timeline>

        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>

import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
// eslint-disable-next-line import/no-unresolved
import QrcodeVue from 'qrcode.vue'
import axiosIns from '@/libs/axios'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BCardHeader,
    BCardBody,
    AppTimeline,
    AppTimelineItem,
    QrcodeVue,
    // Local Components

  },
  data() {
    return {
      value: 'https://example.com',
      size: 115,
      histories: [],
      colis: '',
      fields: ['désignation', 'prix', 'quantité', 'total'],
      items: [

      ],
      product: [],
      tableVariants: [
        'none',
        'primary',
        'secondary',
        'info',
        'danger',
        'warning',
        'success',
        'light',
        'dark',
      ],
    }
  },
  created() {
    this.getProduct()
  },
  methods: {
    async getProduct() {
      const { id } = this.$route.params
      try {
        const { data } = await axiosIns.get(`/products/detail/${id}/`)
        this.product = data
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },

}
</script>
